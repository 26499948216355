@import '../functions';
@import '../variables';
@import '../mixins';

// HOME V3

.hero.hero-home{
	background-image: url('../../img/background/bg-home-v3.jpg');
	background-size: cover;
	background-position: center top;
	position: relative;

	.hero-content{
		position: relative;

		.overlay{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(32, 42, 52, .4);
		}
	}

	.hero-content-title{
		z-index: 1;
		height: 100%;
		top: inherit;
		bottom: inherit;
		align-items: flex-start;
		text-align: left;

		h1,
		h2{
			margin-left: inherit;
		}

		h2{
			font-family: $font-family-base;
			font-weight: $font-weight-light;
			line-height: 1.2;
		}

		.cta-container{
			width: 100%;

			.btn{
				margin-right: $spacer / 2;
				font-family: $font-family-alt;
				font-size: rem(14);
				font-weight: normal;
				padding: .5rem 2rem;
			}

			@include media-breakpoint-down(xs) {
				margin-top: $spacer;

				.btn{
					width: 100%;
					margin-bottom: $spacer;
				}
			}

			@include media-breakpoint-only(sm) {
				margin-top: $spacer * 2;
			}

			@include media-breakpoint-up(md) {
				margin-top: $spacer * 2;
			}
		}
	}
}



.home{

	&.universes-alt{
		background-color: $white;
		padding: $spacer * 2 0;
		text-transform: uppercase;
		letter-spacing: .1em;
		font-size: rem(13);

		@include media-breakpoint-down(sm) {
			text-align: center;
		}

		h2{
			font-weight: bold;
			font-size: rem(13);
			@include media-breakpoint-up(md) {
				margin: 0;
			}
		}

		ul{
			list-style: none;
			margin: 0;
			padding: 0;

			@include media-breakpoint-down(sm) {
				text-align: center;

				li{
					margin: 0 $spacer $spacer / 3;
				}
			}
			@include media-breakpoint-up(md) {
				text-align: right;

				li{
					margin-left: $spacer * 2;
				}
			}

			li{
				display: inline-block;

				a{
					color: $secondary;
				}
			}
		}
	}

	&.stories,
	&.newsletter{
		.title{
			h2{
				margin-left: 0;
			}
		}
	}

	&.stories:not(.univers-sport){
		background-position: bottom center;
		background-size: cover;
		background-color: #eff0f2;

		@include media-breakpoint-up(sm) {
			background-image: url('../../img/background/bg-office2.jpg');
		}
		
		background-color: #edeef0;
		background-repeat: no-repeat;

		@include media-breakpoint-down(sm) {padding-top: $spacer * 2;}
		@include media-breakpoint-only(md) {padding-top: $spacer * 3;}
		@include media-breakpoint-up(lg) {padding-top: $spacer * 5;}

		@include media-breakpoint-only(sm) {background-position: top center;}
		@include media-breakpoint-only(md) {background-position: top 32% center;}
		@include media-breakpoint-only(lg) {background-position: top 52% center;}
		@include media-breakpoint-up(xl) {background-position: top calc(380px - 57vw) center;}

		.title{
			@include media-breakpoint-only(md) {flex: 0 0 50%;}
			@include media-breakpoint-up(lg) {flex: 0 0 430px;}

			@include media-breakpoint-down(sm) {margin-bottom: $spacer;}
			@include media-breakpoint-only(md) {padding-right: 40px;}
			

			h1{
				font-size: rem(30);
				@include media-breakpoint-down(xs) {font-size: rem(22);}
				@include media-breakpoint-only(md) {font-size: rem(24);}
			}

			h2{
				font-size: rem(28);
				margin-left: 40px;
				margin-top: 0;
				@include media-breakpoint-down(xs) {font-size: rem(22);}
				@include media-breakpoint-only(md) {font-size: rem(24);}
			}

			p{
				@include media-breakpoint-down(xs) {font-size: rem(14);}
				font-size: rem(16);
				margin-top: $spacer;
			}
		}

		.stories-wrapper{
			justify-content: space-between;

			@include media-breakpoint-down(xs) {padding-bottom: $spacer * 2;}
			@include media-breakpoint-only(sm) {padding-bottom: 460px;}
			@include media-breakpoint-only(md) {padding-bottom: 460px;}
			@include media-breakpoint-up(lg) {padding-bottom: 40vw;}
		}

		.form-container{
			flex: 0 1 50%;
		}
	}

	&.how_to:not(.univers-sport){
		background-color: $white;
		padding-bottom: $spacer * 3;
		background-image: none;

		.how_to-wrapper{	
			@include media-breakpoint-only(sm) {margin-top: -370px;}
			@include media-breakpoint-only(md) {margin-top: calc(-520px + 20vw);}
			@include media-breakpoint-only(lg) {margin-top: calc(-430px + 10vw);}
			@include media-breakpoint-up(xl) {margin-top: calc(-130px - 20vw);}
		}

		.how_to-overview{
			justify-content: flex-start;

			.title{
				margin-bottom: $spacer * 2;

				h1{
					font-weight: 300;

					strong{
						font-weight: 700;
					}
				}
			}

			.btn-link{
				color: $secondary;
				padding: 0;
				font-weight: 700;
				letter-spacing: .2em;

				&.active,
				&:hover,
				&:focus,
				&:active{
					color: $white;
					text-decoration: none;
				}

			}
		}
	}

	&.newsletter{
		padding-top: $spacer * 5;

		h1{
			font-size: rem(30);
		}

		h2{
			font-size: rem(28);
			padding-left: 15px;

			@include media-breakpoint-down(sm) {
				position: inherit !important;
				top: 0 !important;
			}
		}

		p{
			font-size: rem(16);
		}

		h1,
		h2{
			display: inline-block;
		}
	}

	&.logos{
		background-color: $white;
		box-shadow: 0 0 50px rgba(0, 0, 0, .2);
		padding: $spacer 0 0;
		position: relative;
		z-index: 2;

		.logos-list{
			list-style: none;
			margin: 0;
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			width: 100%;

			.logos-list-item{
				display: inline-flex;
				align-items: center;
				justify-content: center;
				flex: 0 0 96px;
				width: 96px;
				height: 58px;
				margin: 0 $spacer * 2 $spacer;

				img{
					max-width: 100%;
					max-height: 100%;
				}
			}
		}
	}

	&.testimonial{
		background-position: center right;
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;

		.overlay{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: $white;
			opacity: .8;

			@include media-breakpoint-up(md) {display: none;}
		}

		.container{
			position: relative;
			z-index: 2;
		}

		@include media-breakpoint-down(sm) {padding: $spacer * 2 $spacer;}
		@include media-breakpoint-only(md) {padding: $spacer * 4 $spacer;}
		@include media-breakpoint-only(lg) {padding: $spacer * 5 $spacer;}
		@include media-breakpoint-up(xl) {padding: $spacer * 7 $spacer;}

		h1{
			font-size: rem(30);
		}

		h2{
			font-size: rem(28);
			font-family: $font-family-script;
			color: $primary;
			margin-left: 40px;
			margin-bottom: $spacer;

			@include media-breakpoint-down(xs) {font-size: rem(24);}
			@include media-breakpoint-up(sm) {font-size: rem(28);}
		}

		p{
			margin-bottom: $spacer;

			&:last-child{
				margin-bottom: 0;
			}
		}
	}

	// b2b blocks

	&.services{
		background-color: $white;
		padding: 7rem 0;

		.left{
			display: flex;
			flex-direction: column;
			justify-content: center;

			h1{
				font-size: rem(22)
			}

			h2{
				font-size: rem(24);
				font-weight: 400;
			}

			@include media-breakpoint-down(md) {
				margin-bottom: $spacer * 2;
			}
		}

		.right{
			border: 1px solid $lighter;
			border-bottom: transparent;
		}

		.services-wrapper{
			padding: $spacer;
		}

		.service{
			text-align: center;
			position: relative;

			&:first-child{
				@include media-breakpoint-down(md) {
					border-bottom: 1px solid $lighter;
					padding: $spacer $spacer $spacer * 2;
				}

				@include media-breakpoint-up(lg) {
					border-right: 1px solid $lighter;
				}
			}

			&:last-child{
				@include media-breakpoint-down(md) {
					padding: $spacer * 2 $spacer $spacer;
				}
			}

			.title{
				font-weight: 700;
				font-size: rem(20);

				.script{
					display: block;
					color: $kaki;
					font-weight: 400;
				}
			}

			hr{
				background-color: $kaki;
				width: 50px;
			}

			.price-model{
				text-transform: uppercase;
				font-weight: 700;
				margin-bottom: $spacer / 2;
			}

			p{
				font-size: rem(12);
				color: $secondary;
				font-family: $font-family-alt;
				margin: $spacer 0 $spacer * 2;
			}

			.action{

				@include media-breakpoint-up(lg) {
					position: absolute;
					bottom: calc(-#{$spacer} - 17px);
					left: 50%;
					margin-left: -17px;
				}
			}
		}

		.services-details{
			position: relative;
			top: -1px;

			.left{
				border: 1px solid $lighter;
				padding: $spacer / 2 $spacer;
				background-color: $white;
				border-right: transparent;
			}

			.left{

			}

			.right{
				border-left: transparent;
				border-top: transparent;
				border-bottom: 1px solid $lighter;
			}

			.services-details-trigger{
				text-transform: uppercase;
				font-size: rem(10);
				color: $secondary;
				font-family: $font-family-alt;
			}
		}
	}

}